<template>
  <UiPopup
    :model-value="modelValue"
    title="Delete location numbers?"
    description="Are you sure you want to delete the location numbers?"
    primary-button-text="Delete"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="deleteLocation"
  >
  </UiPopup>
</template>

<script setup lang="ts">
import type { Local } from '@/types'
import { useUiStore } from '~/store/ui'

const emits = defineEmits(['update:modelValue', 'input'])
const uiStore = useUiStore()

type Props = {
  modelValue: boolean
  location: Local
  locals: Local[]
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const deleteLocation = async () => {
  loading.value = true
  const phones = props.locals.filter((el) => {
    return !el.addAction && el.parentId === props.location.id
  })
  const phonesIds = phones.map((el) => el.id.toString())
  try {
    await useDeletePhones({ ids: phonesIds })
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
